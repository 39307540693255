$FontSans: 'Source Sans Pro', sans-serif;
$bgcolor: #f7f9fa;
$white: #ffffff;
$black: #000000;
$TxtGray: #374347;
$mid-grey: #b3c6cd;
$red: #f5474a;
$green: #44b5ab;
$frenchPass: #b3eafb;
$blackSqueeze: #e1eef3;
$limedSpruce: #364347;
$grey: #666666;
$lighter-blue: #d6e1e5;
$light-blue: #02baf2;
$hit_gray: #93a4aa;
$dark_red: #9e0b0f;
$azureBlue: #0478ff;

$black30opac: rgba(0, 0, 0, 0.3);
$FontSFRegular: 'SFTextRegular';
$white: #ffffff;
$greyF5: #f5f5f5;
$grey03: #030303;
$grey77Opac8: rgba(77, 77, 77, 0.1);
$royal-blue: #0076ff;
$white80Opac: #f5f5f5;

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  transition: background-color 0.5s;
  font-family: $FontSans;
  background: $white;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  outline: none;
}
//.anim { transition: all 0.4s ease; -webkit-transition: all 0.4s ease; -ms-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -o-transition: all 0.4s ease; }
.container {
  max-width: 768px;
}

.standard_line_height * {
  line-height: 120% !important;
}

.txtBlack {
  color: $black !important;
}

p {
  margin: 0 0 10px 0;
  line-height: 130%;
}

.p0 {
  padding: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pb70 {
  padding-bottom: 70px !important;
}

.m0 {
  margin: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}

.gap10 {
  height: 10px;
}
.gap20 {
  height: 20px;
}
.gap30 {
  height: 30px;
}
.gap40 {
  height: 40px;
}
.gap50 {
  height: 50px;
}

.clear {
  clear: both;
}
.show {
  display: block;
}
.hide {
  display: none;
}

.loader.show {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  bottom: 0;
  z-index: 9999;
  background: transparent;
}

.loader img {
  width: 50px;
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.97);
  padding: 20px 0; /*border-bottom: 1px solid $mid-grey;*/
  height: 64px;
  z-index: 1;
}
header .container {
  padding: 0 10px;
}
.header-btn,
header h1 {
  font-size: 18px;
  color: $white;
}
.header-btn {
  background: none;
  border: none;
  width: 20%;
  display: inline-block;
  text-align: left;
  padding: 0;
}
header h1 {
  width: 60%;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 24px;
}
header .left {
  width: 20%;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
header .right {
  width: 20%;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
header .lnk {
  font-size: 20px;
  color: $light-blue;
  margin: 0;
  line-height: 24px;
}
header .lnk:hover {
  color: $TxtGray;
  text-decoration: none;
}
header .title {
  width: 60%;
  float: left;
  margin: 0 20%;
  // font-size: 24px;
  font-size: 18px;
  font-weight: 600;
  color: $black;
  text-align: center;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
}
header .back_arrow {
  float: left;
}
header .back_arrow img {
  float: left;
}

.noscroll {
  overflow: initial;
}
.heightAuto {
  height: auto !important;
}
.width100Per {
  width: 100% !important;
}
.main_content {
  width: 100%;
  float: left;
  margin-top: 64px;
  padding: 15px;
}
.bottom_fix {
  position: fixed;
  bottom: 0;
  padding: 5px 15px 15px 15px;
  background: $white;
  left: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  z-index: 10;
}
.bottom_fix .wrapper {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.bottom_fix .btn {
  width: 100%;
  border-radius: 4px;
  padding: 12px 15px;
  color: $white;
  background: $light-blue;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.bottom_fix .btn.disabled {
  background: $frenchPass;
}
.bottom_fix .btn:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
}
.bottom_fix .btn.black {
  background: $black;
  color: $white;
  text-transform: initial;
}
.bottom_fix .btn.black:hover {
  background: $blackSqueeze;
  color: $limedSpruce;
  text-transform: initial;
}
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}
.loaderbg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}
.loaderbg .loader_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loaderbg .loader_content .loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}
.loaderbg .loader_content .loaderimg {
  width: 70px;
  height: 70px;
}
.loaderbg .loader_content .loaderimg img {
  width: 100%;
  opacity: 0.5;
}
@keyframes blink {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.drawer_base {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  z-index: 11;
  background: #fff;
  padding-top: 45px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.center_icon_base {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px;
  height: 80px;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 20px 0rgba (0, 0, 0, 0.2);
  -o-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.center_icon {
  position: absolute;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
}
.center_icon .driver {
  position: absolute;
  width: 52px;
  height: 52px;
  overflow: hidden;
  top: 34px;
  left: 34px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.center_icon .driver img {
  width: 100%;
}
.csat.ios header {
  border-top: 20px solid $white;
  height: 75px;
}
.csat.ios .main_content {
  margin-top: 60px !important;
  padding-top: 15px !important;
}
.info {
  font-size: 14px;
  color: #93a4aa;
  font-style: italic;
}

/* DIALOG BOX */
.pg.dialog {
  z-index: 120;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  background: rgba(0, 0, 0, 0.2);
}
.dialogBox {
  width: 270px;
  min-height: 100px;
  padding: 15px;
  font-family: $FontSans;
  position: fixed;
  z-index: 141;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  border-radius: 12px;
  &.bgGray {
    background: $greyF5;
  }
  .title {
    width: 100%;
    text-align: center;
    font-family: $FontSans;
    font-weight: 600;
    margin: 0 0 13px 0;
    color: $grey03;
    font-size: 17px;
    color: #030303;
    margin-top: 8px;
  }
  p {
    text-align: center;
    font-family: $FontSans;
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 30px 0;
    padding: 0 0px;
    font-weight: 600;
  }
  .control {
    width: 100%;
    border-top: 1px solid $grey77Opac8;
    margin: 0;
    .lnk {
      text-align: center;
      font-family: $FontSans;
      text-decoration: none;
      display: block;
      padding: 12px 5px;
      margin: 0;
      color: $royal-blue;
      font-size: 18px;
      font-weight: 600;
      &.strong {
        font-weight: 500;
      }
    }
    .col.bRight {
      border-right: 1px solid $grey77Opac8;
    }
  }
  .eta {
    width: 100%;
    padding: 5px !important;
    margin: 0 0 5px 0;
    height: 24px;
    font-family: $FontSFRegular;
    font-size: 13px;
  }
  &.opac80 {
    background: $white80Opac;
  }
  .form-group {
    input {
      width: 100%;
      font-size: 13px;
      padding: 1px 5px;
    }
  }
}
/* DIALOG BOX */
