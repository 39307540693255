@import '../../variable.scss';

$FontSans: 'Source Sans Pro', sans-serif;
$limedSpruce: #364347;
$light-blue: #02baf2;
$blackSqueeze: #e1eef3;
$bgColor: #f5f8f9;

body {
  background-color: $bgColor !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mr0 {
  margin-left: 0 !important;
}
.mlm20 {
  margin-left: -20px !important;
}
.mrm20 {
  margin-right: -20px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}
.pb0 {
  padding-bottom: 0;
}

.bottom_btn_fix {
  padding-top: 10px;
  background-color: #f5f8f9;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
}
.btn.submit_btn {
  position: relative;
  width: 100%;
  border-radius: 4px;
  padding: 9px 15px;
  color: $white;
  background: $light-blue;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 10px 0 20px 0;
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
  &:hover,
  &:focus,
  &.active {
    background: $light-blue;
    color: $white;
    box-shadow: none;
  }
}
.btn.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.error_wrapper {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
.survey_bg {
  position: relative;
  width: 100%;
  padding-bottom: 0;
  overflow: auto;
  // &::-webkit-scrollbar {
  //   width: 5px;
  //   border-radius: 10px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: $light-blue;
  //   border-radius: 10px;
  //   // outline: 1px solid slategrey;
  // }
}
.survey_wrapper {
  text-align: center;
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
  header {
    position: relative;
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .title {
      width: 100%;
      margin: 0;
      color: #364347;
      max-width: 335px;
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 24px;
      &.msg_title {
        font-size: 24px;
      }
    }
  }
  .main_content {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.survey_hd {
  font-size: 19px;
  font-weight: 700;
  color: #364347;
  line-height: 23px;
  margin: 15px auto 20px auto;
  max-width: 300px;
  &.thanks {
    font-size: 18px;
    font-weight: 400;
  }
  &.gap {
    margin-top: 25px;
    margin-bottom: 25px;
    &.msgSection {
      // margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &.thanks_txt {
    max-width: 260px;
  }
}
.question {
  font-size: 19px;
  color: #364347;
  line-height: 23px;
  margin: 15px auto 20px auto;
  max-width: 300px;
}
.bold {
  font-weight: 700;
}
.center_content_pg {
  background: $bgColor;
  width: 100%;
  // height: 100vh;
  height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .main_hd {
    font-size: 24px;
    font-weight: 700;
    color: $limedSpruce;
    line-height: 23px;
    margin: 0 auto 8px auto;
    max-width: 300px;
  }
  .sub_info {
    font-size: 18px;
    font-weight: 400;
    color: $limedSpruce;
    line-height: 23px;
    max-width: 300px;
    text-align: center;
    white-space: pre;
  }
}
.survey_form {
  .reason_txt_area {
    width: 100%;
    float: left;
    background: #ffffff;
    border: 1px solid #d6e1e5;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    min-height: 160px;
    max-height: 400px;
    line-height: 140%;
    resize: none;
    font-size: 18px;
    color: #364347;
    -webkit-appearance: none;
    &::placeholder {
      color: #b2c6cd;
      font-size: 18px;
      font-weight: 400;
    }
  }
  // .survey_reasons {
  //   text-align: left;
  //   margin-bottom: 10px;
  //   .reason_chekbox {
  //     margin-bottom: 25px;
  //     label {
  //       cursor: pointer;
  //       display: inline-block;
  //       position: relative;
  //       font-size: 20px;
  //       color: #364347;
  //       padding-left: 32px;
  //       margin-right: 10px;
  //       &:before {
  //         content: '';
  //         width: 26px;
  //         height: 26px;
  //         position: absolute;
  //         left: 0;
  //         top: -3px;
  //         background: url('../../assets/images/Icon_Unchecked_Survey.svg') left
  //           center no-repeat;
  //       }
  //     }
  //     input[type='checkbox'] {
  //       display: none;
  //       &:checked {
  //         + {
  //           label {
  //             &:before {
  //               background: url('../../assets/images/Icon_Checked_Survey.svg')
  //                 left center no-repeat;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }
  label {
    border-radius: 50%;
    border: solid 1px #b2c6cd;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 10px 0;
    color: #364347;
    font-size: 18px;
    font-weight: normal;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
  input[type='radio']:checked + label,
  input[type='checkbox']:checked + label {
    background: #364347;
    color: #fff;
  }
}
.answer_wrapper {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .single_answer_wrapper{
    margin: 0px 5px;
    &.notes{
      width: 100%;
    }
  }
  .answer-hint{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 17px;
    color: $nevada;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

@media screen and (min-height: 800px) {
  .pb100 {
    padding-bottom: 0;
    .bottom_btn_fix {
      padding-top: 0;
      position: relative;
    }
  }
}
